import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import OrderContext from 'context/order';
import UserContext from 'context/user';
import { api, apiClient, axios } from 'helper/api/client';
import global from 'helper/global';
import revalidate from 'helper/revalidate';
import log from 'helper/log';
import permission from 'helper/permission';
import manualStopMessages from 'helper/manualStopMessages';

const defaults = {
    loggedOutReason: null,
    authenticated: false,
    accountMenuOpen: false,
    setToken: () => {
    },
    setLoggedOutReason: () => {
    },
    setAuthenticated: () => {
    },
    doLogOut: (event, reason) => {
    },
}
const AuthContext = createContext(defaults);

let interval;

export const AuthContextProvider = (props) => {
    const userContext = useContext(UserContext);
    const {setOrders} = useContext(OrderContext);
    const [csrf, setCsrf] = useState(false);
    const [authenticated, setAuthenticated] = useState(window.localStorage.getItem('authenticated') === 'true');
    const [loggedOutReason, setLoggedOutReason] = useState(null);
    const [token, setToken] = useState(window.localStorage.getItem('token') || '');
    const [accountMenuOpen, setAccountMenuOpen] = useState(defaults.accountMenuOpen);
    const [shouldForceUpdateCustomer, setShouldForceUpdateCustomer] = useState(false);
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const doLogOut = (event, reason = 'loggedOut') => {
        if (navigator.credentials && navigator.credentials.preventSilentAccess) {
            navigator.credentials.preventSilentAccess();
        }
        global.formSuccess = null;
        setLoggedOutReason(reason);
        setAuthenticated(false);
        removeTokenCookie();
        log.setCustomer({ accountNumber: '', type: '', sector: '' });
        setOrders(null);
        api.flushCache();
        api.post('customer/logout', {reason: reason});
    }
    const removeTokenCookie = () => {
        document.cookie = "fg_session=;path=/;domain=" + process.env.REACT_APP_SESSION_DOMAIN + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    };
    const getCustomer = useCallback(() => {
        setShouldForceUpdateCustomer(false);
        api.get('customer')
            .then(response => {
                if (response.data.response.customer && response.data.response.customer !== false) {
                    permission.set(response.data.response.customer.permissions);
                    manualStopMessages.set(response.data.response.customer.manualStopReason);
                    userContext.setUser(response.data.response.customer);
                } else if (response.data.response.revalidate) {
                    revalidate(response.data.revalidateToken);
                    removeTokenCookie();
                } else {
                    setLoggedOutReason('invalid');
                    setAuthenticated(false);
                    removeTokenCookie();
                }
            }).catch(error => {
                api.error(error);
                setLoggedOutReason('invalid');
                setAuthenticated(false);
                removeTokenCookie();
            });
    }, [userContext]);
    const forceUpdateCustomer = () => {setShouldForceUpdateCustomer(true)};
    useEffect(() => {
        api.get('sanctum/csrf-cookie').then(() => {
            setCsrf(true);
        }).catch(error => {
            api.error(error);
        });
    }, []);
    useEffect(() => {
        window.localStorage.setItem('token', token);
        if (token) {
            document.cookie = "fg_session=" + encodeURI(token) + ";path=/;domain=" + process.env.REACT_APP_SESSION_DOMAIN + ";max-age=" + 60*60*24;
        }
    }, [token]);
    useEffect(() => {
        if (authenticated) {
            if (shouldForceUpdateCustomer || userContext.user.isDefault) {
                // When the tokens are set and the customer refreshes, load it immediately
                getCustomer();
            }
            clearInterval(interval);
            let source = axios.CancelToken.source();
            interval = setInterval(() => {
                // Refresh the customer every 5 minutes
                if (global.canUpdateCustomer) {
                    getCustomer();
                }
            }, (5 * 60 * 1000));
            return () => {
                clearInterval(interval);
                source.cancel();
            }
        }
    }, [authenticated, userContext, getCustomer, shouldForceUpdateCustomer]);
    useEffect(() => {
        window.localStorage.setItem('authenticated', (authenticated ? 'true' : 'false'));
        if (!authenticated) {
            window.localStorage.setItem('_adminId', '');
            window.localStorage.setItem('_adminToken', '');
            setToken('');
        }
    }, [authenticated]);

    return <AuthContext.Provider
        value={ {
            csrf: csrf,
            loggedOutReason: loggedOutReason,
            authenticated: authenticated,
            setToken: setToken,
            setLoggedOutReason: setLoggedOutReason,
            setAuthenticated: setAuthenticated,
            accountMenuOpen: accountMenuOpen,
            setAccountMenuOpen: setAccountMenuOpen,
            forceUpdateCustomer: forceUpdateCustomer,
            token: token,
            doLogOut: doLogOut
        } }>{ props.children }</AuthContext.Provider>;
}

export default AuthContext;